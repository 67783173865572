import * as React from "react";

function Index() {
  React.useEffect(() => {
    if (typeof window != "undefined") {
      window.location.href = "/about";
    }
  }, []);
  return null;
}

export default Index;
